import React from 'react'
import * as BsIcons from 'react-icons/bs'
import * as MdIcons from 'react-icons/md'
import styled from 'styled-components'

const InfobarList = ({ items, otros }) => {
    return (
        <>
            <TdBold>
                {items}:
            </TdBold>
            <Td>
                {otros[items] === true
                    ? <BsIcons.BsFillCheckSquareFill style={{ color: 'green' }} />
                    : (otros[items] === false
                        ? <MdIcons.MdDoNotDisturb style={{ color: 'red' }} />
                        : otros[items])}
            </Td>
        </>
    )
}
const TdBold = styled.td`
    padding: 1px;
    float: left;
    text-align: left;
    width: 50%;
    font-weight: bold;
`;

const Td = styled.td`
    padding: 1px;
    float: left;
    text-align: left;
    width: 50% ;
`;

export default InfobarList

