export const NAVBAR = {
    title: {
        es: 'Mapa de activos logísticos | Panama Logistics Portal',
        en: 'Map of Logistics Assets | Panama Logistics Portal'
    },
    switch: {
        es: 'ES',
        en: 'EN'
    },
    logo: {
        es: 'Activos Logísticos de Panamá',
        en: 'Panama Logistics Assets'
    }
}

export const SIDEBAR = {
    titleemail: {
        es: 'Correo:',
        en: 'Email:'
    },
    email: 'georgiatechpanama@gatech.pa',
    titlewebsite: {
        es: 'Sitio web:',
        en: 'Website:'
    },
    website: {
        link:'www.gatech.pa',
        es: 'https://www.gatech.pa/?lang=es',
        en: 'https://www.gatech.pa/?lang=en'
    },
    titleubication: {
        es: 'Dirección:',
        en: 'Address:'
    },
    ubication: {
        en: 'Marbella Office Plaza, Office 5B, Aquilino De La Guardia, Marbella, Bella Vista',
        es: 'Marbella Office Plaza, Oficina 5B, Aquilino De La Guardia, Marbella, Bella Vista'
    },
    titlecontact: {
        es: 'Teléfono:',
        en: 'Telephone:'
    },
    contact: '(507) 395-3030',
    titleregresar: {
        es: 'Ir al Portal logístico',
        en: 'Go to Panama Logistics Portal'
    },
    linkregresar: {
        es: 'https://logistics.gatech.pa/plataforma-logistica/activos-logisticos/generales/',
        en: 'https://logistics.gatech.pa/en/logistics-platform/logistics-assets/generals/'
    },
    information: {
        es: 'Más Información',
        en: 'More Information'
    }
}

