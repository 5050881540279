// COMPONENTES
import Menu from './NavbarMenu';
import Logo from './NavbarLogo';
import NavSwitch from './NavSwitch'
import React from 'react'
import styled from 'styled-components'
import {NAVBAR} from '../../Data/InfoJson.js'

const Navbar = ({ setShow, show, themes, setThemes, idioma, setIdioma, height, toggleOnInfo }) => {// TITULO DE LA PAGINA
    const title = idioma ? NAVBAR.title.es : NAVBAR.title.en
    document.title = title
    return (
        <DivNavbar themes={themes}>
            <DivMenuLogo>
                <Menu setShow={setShow} show={show} themes={themes} />
                <Logo themes={themes} idioma={idioma} />
            </DivMenuLogo>
            <NavSwitch  toggleOnInfo={toggleOnInfo} themes={themes} idioma={idioma} setIdioma={setIdioma} setThemes={setThemes} height={height}/>
        </DivNavbar>
    )
}

const DivNavbar = styled.nav`
        position: fixed;
        z-index: 3;
        width: calc(100% - 20px);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
        padding: 0px 10px;
        border-radius: 5px;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        mix-blend-mode: normal;
        backdrop-filter: blur(10px);
        border: 1px  solid  ${(props) => props.themes ? '#ffffff80' : '#1d1d1d80'};
        background-color: ${(props) => props.themes ? '#ffffffe6' : '#434343E6'};
        background: ${(props) => props.themes ? 'linear-gradient(0deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), rgba(255, 255, 255, 0.5);' : 'linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), rgba(67, 67, 67, 0.5);'};
        `;

        // background-color: ${(props) => props.themes ? Colores.Light.nav_background : Colores.Dark.nav_background};

const DivMenuLogo = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
`;
export default Navbar;