const Data = (geodata) => {
  const nivel1 = geodata.filter(
    (x) => x.file && x.visible_tree).map(
      (x) => (x.file)
    )
  const nivel2 = []
  geodata.filter(primer).map(
    (x) => (
      x.tree.subcat.map(
        (y) => (
          y.visible && nivel2.push(y.filejson)
        )
      )
    )
  )
  const geolayer = [...nivel1, ...nivel2]
  return geolayer
}

function primer(item) {
  const arreglo = []
  if (item.tree.subcat) {
    arreglo.push([...item.tree.subcat])
  }
  else return
  return arreglo
}
export default Data;