import React from 'react'
import List from './InfobarList'
import styled from 'styled-components'
const InfobarText = ({ x, infoLayer }) => {
    if (x) {
        const { name, id, height, iconcat, icon, intl, category, FillColor, LineColor, Fondo, tam, ...otros } = infoLayer.properties
        let claves = Object.keys(otros);
        return (
            <DivInfoList>
                <H1Type>
                    {category}
                </H1Type>
                <Table>
                    <TbodyLista key={name}>
                        {claves.map(
                            (items, index) => {
                                return (
                                    <TrObjetoLista key={index}>
                                        <List items={items} key={index} otros={otros} />
                                    </TrObjetoLista>
                                )
                            }
                        )}
                    </TbodyLista>
                </Table>
            </DivInfoList>
        )
    }
    else return <></>
}

const TbodyLista = styled.tbody`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const TrObjetoLista = styled.tr`
    color: #000000;
    font-size: 12px;
    justify-content: center;
    text-align: left;
`;

const DivInfoList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Table = styled.table`
    float: left;
    width: 100%;
`;

const H1Type = styled.h1`
    color: #1d1d1d;
    font-size: 16px;
    width: 100%;
    font-weight: 600;
    text-align: left;
    
`;

export default InfobarText