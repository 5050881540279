import axios from "axios";

export function AppIcon(geodata) {
  geodata.forEach((x) => {
    if (x.tree.subcat) {
      x.tree.subcat.forEach((y) =>
        y.filejson.features.forEach((z) => {
          if (z.geometry.type === "Point" && z.properties.iconcat !== "H" && z.properties.iconcat !== "COMB") {
            z.properties.icon = z.properties.iconcat;
            z.properties.tam = 2;
          }
        })
      );
    } else {
      x.file.features.forEach((m) => {
        if (m.geometry.type === "Point" && m.properties.iconcat !== "H" && m.properties.iconcat !== "COMB") {
          m.properties.icon = m.properties.iconcat;
          m.properties.tam = 2;
        }
      });
    }
  });
}

export function Filter(geodata) {
  const ni1 = [];
  geodata.forEach((x, indexx) => {
    if (x.file) {
      // NIVEL 1
      ni1.push({ indexx: indexx, ...x.file });
    } else {
      // NIVEL 2
      x.tree.subcat.forEach((y, indexy) => {
        ni1.push({ indexx: indexx, indexy: indexy, ...y.filejson });
      });
    }
  });
  const ni2 = [];
  let i = 0;
  ni1.forEach((x) => {
    x.features.forEach((y) => {
      if (y.geometry.type === "Point" && y.properties.icon !== "H" && y.properties.icon !== "COMB") {
        const { name, id, category } = y.properties;
        ni2.push({
          name,
          id: i,
          idn: id,
          category,
          coordinates: y.geometry.coordinates,
          indexx: x.indexx,
          indexy: x.indexy,
        });
        i++;
      }
    });
  });
  const unico = ni2.filter(
    (v, i, a) =>
      a.findIndex((v2) =>
        ["indexx", "idn", "name", "type"].every((k) => v2[k] === v[k])
      ) === i
  );
  return unico;
}

export function Asignacion_Geojson(geodata, result) {
  geodata.map((item) => {
    const data = result.find((element) => {
      if (item.apikey && element.jname === item.apikey) {
        return element.json_file;
      }
      else return null
    });
     if (data){
        item.file = data.json_file;
     }
     else {
        item.file = null
     }
    item.tree.subcat &&
      item.tree.subcat.map((subitem) => {
        const data = result.find((element) => {
            if (subitem.apikey && element.jname === subitem.apikey) {
              return element.json_file;
            }
            else return null
          });
        subitem.filejson = data.json_file;
      });
  });
//   geodata[0].tree.subcat[0].filejson = result.data[0].json_file; // Panama Canal Railway
//   geodata[0].tree.subcat[1].filejson = result.data[2].json_file; // Weigh Stations
//   geodata[0].tree.subcat[2].filejson = result.data[16].json_file; // Tolls
//   geodata[0].tree.subcat[3].filejson = result.data[3].json_file; // Panama Metro
//   geodata[0].tree.subcat[4].filejson = result.data[26].json_file; // Charging
//   geodata[1].tree.subcat[0].filejson = result.data[11].json_file; // Navigation area
//   geodata[1].tree.subcat[1].filejson = result.data[10].json_file; // Main Components
//   geodata[2].tree.subcat[0].filejson = result.data[9].json_file; // Containers
//   geodata[2].tree.subcat[1].filejson = result.data[7].json_file; // General cargo
//   geodata[2].tree.subcat[2].filejson = result.data[6].json_file; // Liquid Bulk
//   geodata[2].tree.subcat[3].filejson = result.data[4].json_file; // RoRo
//   geodata[2].tree.subcat[4].filejson = result.data[8].json_file; // Dry bulk
//   geodata[2].tree.subcat[5].filejson = result.data[5].json_file; // Passengers
//   geodata[3].tree.subcat[0].filejson = result.data[15].json_file; // Air International
//   geodata[3].tree.subcat[1].filejson = result.data[14].json_file; // Air Local
//   geodata[4].tree.subcat[0].filejson = result.data[20].json_file; // Colon Free Zone
//   geodata[4].tree.subcat[1].filejson = result.data[21].json_file; // Panama Pacifico
//   geodata[4].tree.subcat[2].filejson = result.data[22].json_file; // City of Knowledge
//   geodata[4].tree.subcat[3].filejson = result.data[13].json_file; // Free Trade Zones
//   geodata[4].tree.subcat[4].filejson = result.data[23].json_file; // Logistics bonded warehouses
//   geodata[4].tree.subcat[5].filejson = result.data[25].json_file; // Logistics bonded warehouses
//   geodata[5].tree.subcat[0].filejson = result.data[12].json_file; // Logistics parks
//   geodata[6].tree.subcat[0].filejson = result.data[17].json_file; // boarder crossings
//   geodata[7].file = result.data[18].json_file; // hub for humanitarian logistics
//   geodata[8].file = result.data[19].json_file; // georgia tech panama
}

export function ErroresApi(msj) {
  const data = {
    mensaje: msj,
  };
  axios
    .post(process.env.REACT_APP_API_LOGS, data, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ACCESS_KEY_LOGS}`,
      },
    })
    .then(function (response) {})
    .catch(function (error) {});
}
