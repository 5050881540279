import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import React, { useCallback, useState, useEffect } from 'react'
import { FlyToInterpolator } from 'deck.gl'
import Data from "../../Process/Data";
import { point, distance } from "@turf/turf";

const AutoComplete = ({ viewState, quitaricon, setShowInfobar, setInfoLayer, themes, idioma, setInitialViewState, geodata, datosBuscar, setDatos, setShow }) => {
    const [pointmap, setPoint] = useState({})
    const [newPoint, setNewPoint] = useState({})
    const [cadena, setCadena] = useState('')
    const [pase, setPase] = useState(false)
    useEffect(() => {
        if (pointmap) {
            if (typeof pointmap.name !== 'undefined') {
                if (cadena !== pointmap.name) {
                    setShowInfobar(false)
                    setDatos(Data(geodata))
                }
            }
        }
    }, [cadena, pointmap, newPoint]);

    // funcion que hace el flyto
    const goToPanama = useCallback((coor, tiempo) => {
        setInitialViewState({
            longitude: coor[0],
            latitude: coor[1],
            zoom: 15,
            pitch: 45,
            bearing: 0,
            transitionDuration: 1000,
            transitionInterpolator: new FlyToInterpolator()
        })
    }, []);
    // calcula el tiempo
    function Tiempo(puntoa) {
        const temporal = { ...viewState }
        const { latitude, longitude } = temporal
        const puntob = [longitude, latitude]
        var from = point(puntoa);
        var to = point(puntob);
        var distan1 = distance(from, to);
        var distan = distan1
        if (distan1 < 5) {
            distan = 1
        }
        else if (distan1 > 5) {
            distan = 1.5
        }
        else if (distan1 > 100 && distan1 < 200) {
            distan = 2
        }
        else {
            distan = 2
        }
        return distan
    }

    const handleOnSearch = (textoenbuscador, results) => {
        setCadena(textoenbuscador)
    }

    const handleOnClear = () => {
        quitaricon()
        const { indexx, indexy } = newPoint
        if (pase === false && indexx & indexy) {
            setShowInfobar(false)
            if ((typeof indexy !== 'undefined')) {
                geodata[indexx].tree.subcat[indexy].visible = false;
            }
            else {
                geodata[indexx].visible_tree = false;
            }
            setDatos(Data(geodata))
        }
    }

    const handleOnSelect = (item) => {
        quitaricon()
        try {
            quitaricon()
            setCadena(item.name)
            setNewPoint(item)
            const { indexx, indexy, idn } = item
            if ((typeof indexy !== 'undefined')) {
                if (geodata[indexx].tree.subcat[indexy].visible === true) {
                    setPase(true)
                } else {
                    setPase(false)
                    geodata[indexx].tree.subcat[indexy].visible = true
                }
                geodata[indexx].tree.subcat[indexy].filejson.features.forEach(
                    (x) => {
                        if (x.properties.id === idn && x.properties.icon !== 'H' && x.geometry.type === 'Point') {
                            x.properties.icon = 'QQ'
                            x.properties.tam = 4
                            setShowInfobar(true)
                            setInfoLayer({ ...x })
                            setPoint({ ...x.properties })
                        }
                    }
                )
            }
            else {
                if (geodata[indexx].visible_tree === true) {
                    setPase(true)
                } else {
                    setPase(false)
                    geodata[indexx].visible_tree = true;
                }
                geodata[indexx].file.features.forEach(
                    (x) => {
                        if (x.properties.id === idn && x.properties.icon !== 'H' && x.geometry.type === 'Point') {
                            x.properties.icon = 'QQ'
                            x.properties.tam = 4
                            setShowInfobar(true)
                            setInfoLayer({ ...x })
                            setPoint({ ...x.properties })
                        }
                    }
                )
            }
            setDatos(Data(geodata))
            goToPanama(item.coordinates, Tiempo(item.coordinates))
            const { innerWidth: width } = window;
            if (width < 800) {
                setShow(() => false)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const formatResult = (item) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ textAlign: 'left', display: 'inline', fontSize: '14px', cursor: 'pointer' }}>{item.name}</div>
                <div style={{ textAlign: 'left', display: 'inline', fontSize: '12px', cursor: 'pointer' }}>{item.category}</div>
            </div>
        )
    }
    
    return (
        <div className="App">
            <header className="App-header">
                <div className="xyz" style={{ width: '100%', padding: '0px 10px', margin: '10px 0px' }}>
                    <ReactSearchAutocomplete
                        //inputSearchString={}
                        items={datosBuscar}
                        onSearch={handleOnSearch}
                        onSelect={handleOnSelect}
                        onClear={handleOnClear}
                        formatResult={formatResult}
                        styling={themes ? Light : Dark}
                        placeholder={idioma ? "Buscar..." : "Search..."}
                        fuseOptions={
                            {
                                isCaseSensitive: false,
                                findAllMatches: true,
                                shouldSort: true,
                                threshold: .3,
                                ignoreLocation: true,
                                maxPatternLength: 100,
                                minMatchCharLength: 1,
                                keys: [
                                    "name",
                                ]
                            }
                        }
                    />
                </div>
            </header>
        </div>
    )
}

const Dark = {
    backgroundColor: '#626D71',
    borderRadius: '4px',
    height: 40 + 'px',
    iconColor: "white",
    lineColor: "white",
    color: "white",
    placeholderColor: "#C4C4C4",
    hoverBackgroundColor: "#C4C4C4",
    border: "1px solid #626D71",
}

const Light = {
    backgroundColor: "white",
    borderRadius: '4px',
    height: 40 + 'px',
    placeholderColor: "grey",
    iconColor: "grey",
    lineColor: "rgb(232, 234, 237)",
    color: "#212121",
    hoverBackgroundColor: "#eee",
    border: "1px solid #dfe1e5",
}

export default AutoComplete