// COMPONENTES
import SubMenu from './SidebarLevel1'
import { Colores } from '../../Style/Themes'
import SidebarRegresar from "./SidebarReturn";
import React from 'react'
import AutoComplete from '../Searchbar/SidebarAutocomplete';
import styled from 'styled-components';
const Sidebar = ({ width, toggleOnInfo, setSubnav, FlyToPoint, setNivel, viewState, quitaricon, setShowInfobar, setListbar, datosBuscar, setInitialViewState, setFondolistbar, show, themes, idioma, geodata, setDatos, setInfoLayer, setTitleListbar, setShowListbar, setShow }) => {
    return (
        <SidebarMain>
            <Buscadordiv show={show} themes={themes}>
                <AutoComplete viewState={viewState} quitaricon={quitaricon} setShowInfobar={setShowInfobar} setInfoLayer={setInfoLayer} setShow={setShow} setDatos={setDatos} themes={themes} idioma={idioma} setInitialViewState={setInitialViewState} geodata={geodata} datosBuscar={datosBuscar} />
            </Buscadordiv>
            <Sidebardiv className='sidebar' show={show} themes={themes}>
                <SidebarWrap>
                    <SidebarRegresar themes={themes} idioma={idioma} />
                    {
                        geodata.map(
                            (items, index) => {
                                return <SubMenu mostrarlistbar={setSubnav} setShowInfobar={setShowInfobar} setInfoLayer={setInfoLayer} FlyToPoint={FlyToPoint} setNivel={setNivel} quitaricon={quitaricon} setFondolistbar={setFondolistbar} items={items} index={index} key={index} themes={themes} idioma={idioma} geodata={geodata} setDatos={setDatos} setListbar={setListbar} setTitleListbar={setTitleListbar} setShowListbar={setShowListbar} setShow={setShow} />
                            }
                        )
                    }
                    <Informacion themes={themes}>
                        <div>
                            © 2024 Panama Logistics Assets.
                        </div>
                        <Responsabilidades onClick={() => { toggleOnInfo(); (width < 800) ? setShow(false) : setShow(true) }} themes={themes}>
                            <u>Descargo de responsabilidades</u>
                        </Responsabilidades>
                    </Informacion>
                </SidebarWrap>
            </Sidebardiv>
        </SidebarMain>
    )
}


const Informacion = styled.div`
    z-index:6;
    height: 63px;
    width: calc(100% - 20px);
    margin-bottom: 10px;
    border-radius: 5px;
    position: relative;
    margin: 10px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    color: ${(props) => props.themes ? Colores.Light.text : Colores.Dark.text};
`;

const SidebarMain = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 4;
    position:fixed;
    margin-left: 10px;
    margin-top: 80px;
    height: calc(100% - 105px);
`;

const Buscadordiv = styled.div`
    z-index:6;
    height: 63px;
    width: 330px;
    margin-bottom: 10px;
    border-radius: 5px;
    position: relative;
    display: ${(props) => props.show ? 'block' : 'none'};
    background: ${(props) => props.themes ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), rgba(255, 255, 255, 0.5);' : 'linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), rgba(67, 67, 67, 0.5);'};
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
    border: 1px  solid  ${(props) => props.themes ? '#ffffff80' : '#1d1d1d80'};
    backdrop-filter: blur(20px);
`;

const Sidebardiv = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    height: calc(100% - 80px);
    width: 330px;
    border-radius: 5px;
    color: blue;
    scrollbar-width: thin; 
    display: ${props => props.show ? 'block' : 'none'};
    background: ${(props) => props.themes ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), rgba(255, 255, 255, 0.5);' : 'linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), rgba(67, 67, 67, 0.5);'};
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
    border: 1px  solid  ${(props) => props.themes ? '#ffffff80' : '#1d1d1d80'};
    backdrop-filter: blur(20px);
`;
// background-color:  ${props => props.themes ? Colores.Light.sibebar_background : Colores.Dark.sibebar_background};


const SidebarWrap = styled.div`
    margin-bottom: 10px;
    overflow-y: visible;
    overflow-x: hidden;
    max-height: 100%;
    scrollbar-width: thin;
    ::-webkit-scrollbar 
    {
        width: 0px;
    }
`;

const Responsabilidades = styled.div`
color: ${(props) => props.themes ? '#1d1d1d' : '#ffffff'};
&: hover {
    color: blue;
}
`;

export default Sidebar;
