// LIBRERIAS
import React from 'react'
//import * as MdIcons from 'react-icons/md'
import styled from 'styled-components';
import { Squash as Hamburger } from 'hamburger-react'
// COMPONENTES
import { Colores } from '../../Style/Themes';

const Menu = ({ show, setShow, themes }) => {
    const color = themes ? Colores.Light.menu_button : Colores.Dark.menu_button;
    return (
        <DivMenu onClick={() => setShow((show) => !show)} themes={themes}>
            <div>
                <Hamburger toggled={show} color={color} size={22} />
            </div>
        </DivMenu>
    )
}

const DivMenu = styled.div`
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 2em;
        margin: 0px;
        margin-right: 20px;
        border-radius: 4px;
        height: 40px;
        width: 40px;
        flex-shrink: 0;
        filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
        background-color: ${(props) => props.themes ? '#ECECEC' : '#2A2A2A'};
        : hover{
            font-size: 25px;
            background-color: ${(props) => props.themes ? Colores.Light.menu_background : '#1C1C1C'}; 
        }
        @media (max-width: 800px) {
            margin-right: 10px;
          }  
`;

export default Menu;