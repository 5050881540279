import { NavigationControl, FullscreenControl } from 'react-map-gl';
import * as BiIcons from 'react-icons/bi'
import * as RiIcons from 'react-icons/ri'
import * as MdIcons from 'react-icons/md'
import React from 'react'
const Control = ({ goToPanama, setSatelital, girar, width, toggleOnInfo }) => {
    return (
        <div>
            <div style={{ position: 'absolute', right: 11, top: 80, ...Styles.div }} onClick={() => setSatelital((satelital) => !satelital)} title="Satellite view">
                <RiIcons.RiMap2Line />
            </div>
            <div style={{ position: 'absolute', right: 40, top: 120 }}>
                <NavigationControl />
            </div>
            <div style={{ position: 'absolute', right: 11, top: 220, ...Styles.div }} onClick={() => {goToPanama();}} title="Go to Panama">
                <BiIcons.BiCurrentLocation />
            </div>
            <div style={{ position: 'absolute', right: 11, top: 260, ...Styles.div }} onClick={girar} title="Rotate map" >
                <MdIcons.MdRotate90DegreesCcw />
            </div>
            <div style={{ position: 'absolute', right: 40, top: 300 }}>
                <FullscreenControl />
            </div>
            <div style={{ position: 'absolute', right: 11, top: 340, ...Styles.div, display: (width > 800 ) ? 'none': 'flex' }}>
            <img src='./infodark.png' alt='info' onClick={e => toggleOnInfo()} style={Styles.img}/>
            </div>
        </div>
    )
}

const Styles = {
    div: {
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        width: '29px',
        height: '29px',
        display: 'flex',
        outline: 'none',
        border: 0,
        boxSizing: 'border-box',
        cursor: 'pointer',
        overflow: 'hidden',
        borderRadius: 4 + 'px',
        fontSize: 24 + 'px',
        padding: 2.5 + 'px',
        color: '#333333',
        boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)'
    },
    img:{
        width: '8px',
        justifyContent: 'center',
    }
}

export default Control;