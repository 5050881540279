// LIBRERIAS
import React from 'react'
import { Switch } from '@mui/material'
import * as MdIcons from 'react-icons/md'
import * as BsIcons from 'react-icons/bs'
import styled from 'styled-components'
import { NAVBAR } from '../../Data/InfoJson.js'
import { Colores } from '../../Style/Themes';
const NavSwitch = ({ themes, setThemes, idioma, setIdioma, height, toggleOnInfo }) => {
    return (
        <>

            <DivSwitch>
                <InfoBotton height={height} themes={themes} onClick={e => toggleOnInfo()} >
                    {
                        themes
                            ? <Img src='./infolight.png' alt='info' />
                            : <Img src='./info.png' alt='info' />
                    }

                </InfoBotton>
                <div>
                    <DivSwitchSection>
                        <DivSwitchText themes={themes}>
                            {themes ? <BsIcons.BsSunFill /> : <MdIcons.MdDarkMode />}
                        </DivSwitchText>
                        <Switch checked={themes} id='themes' onChange={() => setThemes(!themes)} size='small' color="warning" />
                    </DivSwitchSection>
                    <DivSwitchSection>
                        <DivSwitchText themes={themes}>
                            {idioma ? NAVBAR.switch.es : NAVBAR.switch.en}
                        </DivSwitchText>
                        <Switch checked={idioma} id='idioma' onChange={() => setIdioma(!idioma)} size='small' color="default" />
                    </DivSwitchSection>
                </div>
            </DivSwitch>
        </>
    )
}
const DivSwitchSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
`;

const InfoBotton = styled.div`
align-items: center;
justify-content: center;
text-align: center;
font-size: 2em;
margin: 0px;
margin-right: 20px;
border-radius: 4px;
height: 40px;
width: 40px;
flex-shrink: 0;
filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
background-color: ${(props) => props.themes ? '#ECECEC' : '#2A2A2A'};
: hover{
    font-size: 25px;
    background-color: ${(props) => props.themes ? Colores.Light.menu_background : '#1C1C1C'}; 
}
@media (max-width: 800px) {
    margin-right: 10px;
  }  
  display:  ${(props) => (props.height > 800) ? 'flex' : 'none'};
`;

const DivSwitch = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;
const DivSwitchText = styled.div`
cursor: default;
    width: 20px;
    margin: 0;
    padding: 0;
    color: ${(props) => props.themes ? 'black' : 'white'}
`;

const Img = styled.img`
    min-height: 25px;
    height: 25px;
`;
export default NavSwitch;