import React, { useState } from 'react'
import { Colores } from '../../Style/Themes'
import styled from 'styled-components'
import DEVD from '../../Img/development.svg'
import DEVL from '../../Img/developmentwhite.svg'
import UNAD from '../../Img/unactiveblack.svg'
import UNAL from '../../Img/unactivewhite.svg'
import { CalcularTiempo, CambiarIcono, Pantalla, PuntoActual } from '../../Process/Process'

const ListbarObject = ({ nivel, geodata, quitaricon, viewState, setInfoLayer, setShowInfobar, item, themes, setCenter, FlyToPoint, setSubnav }) => {
    /* PERMITE QUE SE PUEDAN SELECCIONAR DIFERENTES EN LA LISTA */
    const [isChecked, setIsChecked] = useState(false);
    function SelectOnList(item) {
        if (nivel.length > 1) {
            geodata[nivel[0]].tree.subcat[nivel[1]].visible = true;
        }
        else if (nivel.length > 0) {
            geodata[nivel[0]].visible_tree = true;
        }
        quitaricon();
        setInfoLayer(item);
        setShowInfobar(true);
        setIsChecked(() => !isChecked);
        setSubnav(Pantalla());
        CambiarIcono(item, geodata);
        setCenter(item.geometry.coordinates)
        FlyToPoint(item.geometry.coordinates, CalcularTiempo(item.geometry.coordinates, PuntoActual(viewState)));
    }
    return (
        <>
            <DivObjetoLista themes={themes} onClick={e => SelectOnList(item)}>
                <div style={{ paddingRight: '5px' }}>
                    {
                        (item.properties.status && item.properties.status === 'unactive')
                            ? <img src={themes ? UNAD : UNAL} alt={'img'} style={{ color: 'white', height: '15px' }} />
                            : (item.properties.status && item.properties.status === 'in development')
                                ? <img src={themes ? DEVD : DEVL} alt={'img'} style={{ color: 'white', height: '15px' }} />
                                : <></>
                    }
                </div>
                {item.properties.name}
            </DivObjetoLista>
        </>
    )
}

const DivObjetoLista = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer !important;
    margin-bottom: 5px;
    width: 100%;
    border-radius: 4px;
    height: auto;
    text-align: left;
    padding: 5px;
    font-size: 14px;
    color: ${(props) => props.themes ? 'black' : 'white'};
    background-color: ${(props) => props.themes ? Colores.Light.sibebar_list_background : Colores.Dark.sibebar_list_background};
    &: hover {
        background-color: #467599 !important;
        border-left: 5px solid #1D3354;
        color: white;
    }
`;

export default ListbarObject