import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
if (document.documentElement.clientWidth <= 375) { 
  document.querySelector("meta[name=viewport]").setAttribute(
        'content', 
        'width=device-width, initial-scale=0.85, maximum-scale=0.85, user-scalable=no');
}
ReactDOM.render(
  <React.StrictMode>
    <div>
      <Router>
        <Routes>
          <Route path="/es" element={<App appidioma={true}/>} />
          <Route path="/en" element={<App appidioma={false}/>} />
          <Route path="/" element={<App appidioma={false}/>} />
        </Routes>
      </Router>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();