import React, { useState } from "react";
import { Colores } from "../../Style/Themes";
import Data from "../../Process/Data";
import buttonon from '../../Img/Extras/buttonon.png'
import buttonoff from '../../Img/Extras/buttonoff.png'
import buttonondark from '../../Img/Extras/buttonondark.png'
import buttonoffdark from '../../Img/Extras/buttonoffdark.png'
import styled from 'styled-components'
import { contadordePoint2 } from '../../Process/Sidebar'

function Checkbox({ mostrarlistbar, setInfoLayer, setShowInfobar, FlyToPoint, index, setNivel, quitaricon, setListbar, setShow, setFondolistbar, subitems, subindex, idioma, themes, items, setIsCheck, selectAll, geodata, setDatos, setTitleListbar, setShowListbar }) {
    const [isChecked, setIsChecked] = useState(false);
    const indexm = index;
    const subindexm = subindex;

    function QuitarClick() {
        geodata.forEach(
            (a) => {
                if (a.tree.subcat) {
                    a.tree.subcat.forEach(
                        (b) => (b.list = false))
                }
            }
        )
    }

    function MostrarList() {
        let siono = false;
        geodata.forEach(
            (a) => {
                if (a.tree.subcat) {
                    a.tree.subcat.forEach(
                        (b) => { if (b.list === true) { siono = true } }
                    )
                }
            }
        )
        return siono
    }

    function ocultarsibedar() {
        const { innerWidth: width } = window;
        if (width < 800) {
            setShow(false)
        }
    }

    function Lista(m) {
        const temporal = subitems.list
        ocultarsibedar()
        QuitarClick();
        subitems.list = !temporal
        if (!subitems.visible) {
            Cambio(subitems)
            setShowListbar(true)
            mostrarlistbar(true)
        }
        const es = m.namees
        const en = m.nameen
        setTitleListbar([es, en])
        setFondolistbar(m.Fondo)
        setListbar(m.filejson.features.filter(x => x.geometry.type === "Point" && x.properties.icon !== "H" && x.properties.icon !== "COMB"))
    }

    function Cambio(subitems, tipo = 1, dir = [], punto = {}) {
        try {
            if (tipo === 2) {
                subitems.visible = true;
            } else {
                subitems.visible = !subitems.visible;
            }
            handleOnChange(tipo);
            if (!subitems.visible) {
                items.visible_tree = false
                subitems.list = false;
                setIsCheck(items.visible_tree)
            }
            try {
                if (tipo === 2) {
                    setDatos(Data(geodata))
                    setInfoLayer(punto);
                    setShowInfobar(true);
                    FlyToPoint(dir, 1)
                } else {
                    if (subitems.visible === false) {
                        quitaricon()
                    }
                    setDatos(Data(geodata))
                }
            } catch (e) { }
        } catch (e) { }
    }

    const handleOnChange = (tipo) => {
        selectAll()
        if (tipo === 2) {
            setIsChecked(true);
        }
        else {
            setIsChecked(!isChecked);
        }
        if (isChecked === true) {
            setShowListbar(MostrarList)
        }
    };

    return (
        <>
            <DivSubcategoria themes={themes}>
                <DivTitle>
                    <DivSectionText>
                        <ImgIcon src={subitems.icon} alt={subitems.namees}></ImgIcon>
                        <DivText>{idioma ? subitems.namees : subitems.nameen}</DivText>
                    </DivSectionText>
                </DivTitle>
                <DivList>
                    {
                        items.tree.listbar
                            ? <MostrarLista ocultarsibedar={ocultarsibedar} Cambio={Cambio} setNivel={setNivel} indexm={indexm} subindexm={subindexm} themes={themes} quitaricon={quitaricon} setShowListbar={setShowListbar} MostrarList={MostrarList} Lista={Lista} subitems={subitems} />
                            : <DivVacio></DivVacio>
                    }
                    <DivCheckbox>
                        {
                            subitems.visible
                                ? <Img src={themes ? './checkactivelight.png' : './checkactive.png'} alt={items.namees} onClick={e => Cambio(subitems)} />
                                : <Img src={themes ? './checklight.png' : './check.png'} alt={items.namees} onClick={e => Cambio(subitems)} />
                        }
                    </DivCheckbox>
                </DivList>
            </DivSubcategoria>
        </>
    )
}

const MostrarLista = ({ ocultarsibedar, Cambio, Lista, subitems, setShowListbar, MostrarList, quitaricon, themes, setNivel, indexm, subindexm }) => {
    if (subitems.visible_listbar === true) {
        return (<>
            <DivPestana onClick={e => { Lista(subitems); setShowListbar(MostrarList); quitaricon(); setNivel([indexm, subindexm]) }}>
                <Img
                    src={
                        themes ?
                            (subitems.list
                                ? buttonondark
                                : buttonoffdark)
                            : (subitems.list
                                ? buttonon
                                : buttonoff)
                    }
                    alt='button' />
            </DivPestana>
        </>)
    }
    else if (subitems.visible_flyto === true) {
        return <DivVacio themes={themes} onClick={() => { contadordePoint2(subitems.filejson.features, Cambio, subitems, quitaricon); ocultarsibedar() }}><Img src='./flyto.png' alt='flyto' /></DivVacio>
    }
    else {
        return <DivVacio2></DivVacio2>
    }
}
const Img = styled.img`
    min-height: 22px;
    min-width: 22px;
    height: 22px;
    width: 22px;
`

const DivSubcategoria = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    letter-spacing: 1.3px;
    padding: 10px;
    height: 32px;
    width: 290px;
    margin-top: 5px;
    margin-left: 30px;
    border-radius: 5px;
    background-color: ${(props) => props.themes ? Colores.Light.sibebar_list_background : Colores.Dark.sibebar_list_background};
    color: ${(props) => props.themes ? Colores.Light.text : Colores.Dark.text};
    :hover {
        background-color: #467599 !important;
        border-left: 5px solid #1D3354;
        color: white;
    }
`;
const DivTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 200px;
    align-items: center;
    text-align: center;
    height: 25px;
`;
const DivSectionText = styled.div`
    align-items: center;
    justify-content: center;
    height: 20px;
    display: flex;
    flex-direction: row;
`;
const DivText = styled.div`
    min-height: 18px;
    text-align: left;
    line-height: 15px;
`;
const ImgIcon = styled.img`
    height: 100%;
    margin-right: 10px;
`;
const DivList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    height: 25px;
    width: 60px;
`;
const DivCheckbox = styled.div`
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: center;
    width: 25px;
`;

const DivVacio = styled.div`
    color: #1d1d1d;
    border-radius: 5px;
    display: flex;
    height: 20px; 
    align-items: center;
    justify-content: center;
    width: 20px;
    font-size: 16px;
`;

const DivVacio2 = styled.div`
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
    width: 20px;
    font-size: 16px;
`;

const DivPestana = styled.div`
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
    width: 20px;
    font-size: 16px;

`;

export default Checkbox;