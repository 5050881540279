// THEMES
import { Colores } from '../../Style/Themes'
// LIBRERIAS
import React from 'react'
import styled from 'styled-components'
import {NAVBAR} from '../../Data/InfoJson.js'
const Logo = ({ themes, idioma }) => {
  const { innerWidth: width } = window;
  return (
    <>
      <DivLogo themes={themes} width={width}>
        <LogoImg src='/logo512.png' alt='logo georgia tech'/>
        {idioma ? NAVBAR.logo.es : NAVBAR.logo.en}
      </DivLogo>
    </>
  )
}

const DivLogo = styled.div`
    cursor: pointer;
    align-items: center;
    display: flex;
    height: 30px;
    margin-right: 10px;
    letter-spacing: 1.5px;
    font-size: ${(props) => props.width > 800 ? '22px' : '18px'};
    color: ${(props) => props.themes ? '#1D3354' : Colores.Dark.text};
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;  
    @media (max-width: 800px) {
      margin-right: 5px;
      font-size: 16px;
      line-height: 17px;
    }  
`;

const LogoImg = styled.img`
  height: 45px;
  margin-right: 10px;
  @media (max-width: 800px) {
    margin-right: 5px;
    height: 35px;
  }  
`;
export default Logo;
