// LIBRERIAS
import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components';
import axios from 'axios'
// import LOADING from './Img/loading.gif'
import Navbar from "./Components/Navbar/Navbar";
//import Mapa from "./Components/Mapa/Mapa";
import Maps from "./Components/Maps/Maps";
import Sidebar from './Components/Sidebar/Sidebar'
import { Colores } from "./Style/Themes";
import geodata from './Data/Main'
import Infobar from "./Components/Infobar/Infobar";
import Listbar from './Components/Listbar/Listbar';
import { FlyToInterpolator } from 'deck.gl'
import { AppIcon, Asignacion_Geojson, ErroresApi, Filter } from './Process/AppInit'
import InfoEmpresa from './Components/InfoPanel/InfoEmpresa'
import Politicas from './Components/InfoPanel/Politicas';
export const { innerHeight: height } = window;

export default function App({ appidioma }) {

  const [subnav, setSubnav] = useState(true);
  /* ARREGLO DE LOS GEOJSON QUE MUESTRA EL MAPA */
  const [datos, setDatos] = useState([]);
  /* ALMAECENA EL DATO QUE SE BUSCO EN EL BUSCADOR (ANTERIOR)*/
  const [datosBuscar, setDatosBuscar] = useState([{}])
  /* CENTRO DEL MAPA, PUNTO INICIAL Y PUNTO DE FLYTO */
  const [center, setCenter] = useState([-79.6879, 9.1197]);
  const [servidor, setServidor] = useState(true);
  /* TRUE MODO LIGHT, FALSE MODO DARK */
  const [themes, setThemes] = useState(false);
  /* TRUE ESPAÑOL, FALSE INGLES*/
  const [idioma, setIdioma] = useState(appidioma);
  /* RESOLUCION DE LA PANTALLA */
  const { innerWidth: width } = window;
  /* MUESTRA EL SIDEBAR DEPENDIENDO DE LA RESOLUCION DE LA PANTALLA*/
  const [show, setShow] = useState(width > 800 ? true : false);
  /* MUESTRA EL INFOBAR */
  const [showInfobar, setShowInfobar] = useState(false);
  /* MUESTRA EL LISTBAR */
  const [showListbar, setShowListbar] = useState(false);
  /* ALMACENA INFORMACION DEL INFOBAR */
  const [infoLayer, setInfoLayer] = useState({})
  /* ALMACENA INFORMACION DEL INFOBAR */
  const [listbar, setListbar] = useState([])
  /*TITUTLO DEL LISTBAR, ARREGLO PORQUE ALMACENA VERSION INGLES Y ESPAÑOL */
  const [titlelistbar, setTitleListbar] = useState(['EN', 'ES'])
  /* COLOR DE LA CATEGORIA DEL LISTBAR */
  const [fondolistbar, setFondolistbar] = useState('#000000')
  /* VISTA INICIAL */
  const [initialViewState, setInitialViewState] = useState({
    longitude: -79.68795776367188,
    latitude: 9.119724285282527,
    zoom: 3,
    pitch: 60,
    bearing: 40,
  });
  /* VISTA DEL PUNTO CENTRAL DEL MAPA CUANDO SE MUEVE */
  const [viewState, setViewState] = useState({
    longitude: -79.68795776367188,
    latitude: 9.119724285282527,
    zoom: 0,
    pitch: 0,
    bearing: 0,
  });
  const [nivel, setNivel] = useState([]);

  const FlyToPoint = useCallback((x, time) => {
    setInitialViewState({
      longitude: x[0],
      latitude: x[1],
      zoom: 15,
      pitch: 45,
      bearing: 0,
      transitionDuration: time * 1000,
      transitionInterpolator: new FlyToInterpolator()
    })
  }, []);

  const QuitarClick = () => {
    geodata.forEach(
      (a) => {
        if (a.tree.subcat) {
          a.tree.subcat.map(
            (b) => (b.list = false))
        }
      }
    )
  }

  const ocultar = () => {
    if (show === true && width < 800) {
      setShow(false)
    }
  }

  function quitaricon() {
    AppIcon(geodata)
  }

  useEffect(() => {
    fetchData()
  }, []);


  const fetchData = async () => {
    try {
      const result = await axios.get(process.env.REACT_APP_API, { headers: { "Authorization": `Bearer ${process.env.REACT_APP_ACCESS_KEY}` } });
      // console.log(result)
      try {
        Asignacion_Geojson(geodata, result.data)
        setServidor(true)
        setDatosBuscar(Filter(geodata))
      } catch (error) {
        setServidor(false)
        console.log(error)
        ErroresApi(`NO ASIGNO TODOS LOS GEOJSON CORRECTAMENTE ][ ${error}`)
      }
    } catch (error) {
      setServidor(false)
      console.log(error)
      ErroresApi(`NO SE LOGRO CONECTAR A LA API ][ ${error}`)
    }
  }


  const [info, setInfo] = useState(false);
  const toggleOnInfo = () => {
    setInfo(!info)
  }
  const [resp, setResp] = useState(false);
  const toggleOnResp = () => {
    setResp(!resp)
  }
  return (
    <>
      {servidor === true ? <>
        <BodyDiv themes={themes} height={height}>
          <Navbar toggleOnInfo={toggleOnInfo} show={show} setShow={setShow} themes={themes} setThemes={setThemes} idioma={idioma} setIdioma={setIdioma} height={width} />
          <InfoEmpresa themes={themes} idioma={idioma} visible={info} setVisible={setInfo} />
          <Politicas themes={themes} idioma={idioma} visible={resp} setVisible={setResp} />
          <Infobar themes={themes} infoLayer={infoLayer} showInfobar={showInfobar} setShowInfobar={setShowInfobar} />
          <Listbar subnav={subnav} setSubnav={setSubnav} nivel={nivel} titlelistbar={titlelistbar} geodata={geodata} fondolistbar={fondolistbar} themes={themes} idioma={idioma} listbar={listbar} viewState={viewState} FlyToPoint={FlyToPoint} quitaricon={quitaricon} QuitarClick={QuitarClick} setCenter={(m) => setCenter(m)} setInfoLayer={setInfoLayer} setShowInfobar={setShowInfobar} showListbar={showListbar} setShowListbar={setShowListbar} />
          <Sidebar width={width} setSubnav={(x) => setSubnav(x)} toggleOnInfo={toggleOnResp} setShowInfobar={setShowInfobar} FlyToPoint={FlyToPoint} setNivel={(m) => setNivel(m)} viewState={viewState} quitaricon={quitaricon} setListbar={setListbar} datosBuscar={datosBuscar} setInitialViewState={setInitialViewState} setFondolistbar={setFondolistbar} show={show} themes={themes} idioma={idioma} geodata={geodata} setDatos={setDatos} setInfoLayer={setInfoLayer} setTitleListbar={setTitleListbar} setShowListbar={setShowListbar} setShow={setShow} />
          <MapsDiv onClick={ocultar}>
            <Maps toggleOnInfo={toggleOnInfo} center={center} quitaricon={quitaricon} viewState={viewState} setViewState={setViewState} setInitialViewState={setInitialViewState} initialViewState={initialViewState} themes={themes} datos={datos} action={(j) => setInfoLayer(j)} idioma={idioma} setShowInfobar={setShowInfobar} width={width} />
          </MapsDiv>
        </BodyDiv>
      </>
        : <>
          <Centro> <h1>SIN ACCESO A SERVIDOR </h1></Centro>
        </>
      }
    </>
  );
}


const Centro = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.themes ? Colores.Light.body_background : Colores.Dark.body_background};
  justify-content: center;
  align-items: center;
  color: white;
`;
const BodyDiv = styled.div`
    overflow-y: hidden;
    overflow-x: hidden;
    position: fixed;
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: ${(props) => props.themes ? Colores.Light.body_background : Colores.Dark.body_background};
`;

const MapsDiv = styled.div`
    overflow-y: hidden;
    overflow-x: hidden;
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
`;