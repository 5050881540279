import { point, distance } from "@turf/turf";

export function CalcularTiempo(puntoa, puntob) {
    var from = point(puntoa);
    var to = point(puntob);
    var distan1 = distance(from, to);
    var distan = distan1
    if (distan1 < 5) {
        distan = 1
    }
    else if (distan1 > 5) {
        distan = 1.5
    }
    else if (distan1 > 100 && distan1 < 200) {
        distan = 2
    }
    else {
        distan = 2
    }
    return distan
}

export function CambiarIcono(item, geodata) {
    geodata.forEach(
        (x) => {
            if (x.tree.subcat) {
                x.tree.subcat.forEach(
                    (y) =>
                        y.filejson.features.forEach(
                            (z) => {
                                if (z.properties.name === item.properties.name && z.geometry.type === 'Point' && z.properties.iconcat !== 'H') {
                                    z.properties.icon = 'QQ';
                                    z.properties.tam = 4;
                                }
                            }
                        )
                )
            }
            else {
                x.file.features.forEach(
                    (m) => {
                        if (m.properties.name === item.properties.name && m.geometry.type === 'Point' && x.properties.iconcat !== 'H') {
                            m.properties.icon = 'QQ';
                            m.properties.tam = 4;
                        }
                    })
            }
        }
    )
}

export function Pantalla() {
    const { innerWidth: width } = window;
    if (width < 800) {
        return false
    }
    else {
        return true
    }
}

export function PuntoActual(viewState) {
    const temporal = { ...viewState }
    const { latitude, longitude } = temporal
    const array = [longitude, latitude]
    return array
}