import React from "react";
import { Colores } from "../../Style/Themes";
import INFO from '../../Img/Extras/INFO.svg'
import { SIDEBAR } from '../../Data/InfoJson'
import styled from 'styled-components'
function CheckInfo({ Infoes, Infoen, themes, idioma }) {
    if (!Infoes) {
        return null
    }
    const link = idioma ? Infoes : Infoen
    return (
        <>
            <DivSidebarCheckInfo themes={themes} onClick={() => window.open(link, "_blank")}>
                <DivSection>
                    <ImgInfo src={INFO} alt="icono de info"></ImgInfo>
                    <DivText themes={themes} >
                        {idioma ? SIDEBAR.information.es : SIDEBAR.information.en}
                    </DivText>
                </DivSection>
            </DivSidebarCheckInfo>
        </>
    )
}

const DivSidebarCheckInfo = styled.div`
    color: ${(props) => props.themes ? Colores.Light.text : Colores.Dark.text};
    background-color: ${(props) => props.themes ? Colores.Light.sibebar_list_background : Colores.Dark.sibebar_list_background};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    letter-spacing: 1.3px;
    padding: 10px;
    width: 290px;
    height: 30px;
    margin-top: 5px;
    margin-left: 30px;
    border-radius: 5px;
    cursor: pointer;
    :hover {
        background-color: #467599 !important;
        border-left: 5px solid #1D3354;
        color: white;
    }
`;

const DivSection = styled.div`
    text-decoration: none;
    align-items: center;
    justify-content: center;
    height: 20px;
    display: flex;
    flex-direction: row;
`;

const ImgInfo = styled.img`
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 30px;
        align-items: center;
        height: 18px;
        font-weight: 1000;
`;

const DivText = styled.div`
    color: ${(props) => props.themes ? Colores.Light.text : Colores.Dark.text}
    :hover {
        color: white;
    }
`;

export default CheckInfo;