import React from 'react'
import styled from 'styled-components'
const Politicas = ({ idioma, themes, visible, setVisible }) => {
  if (!visible) {
    return (<></>)
  }
  return (
    <Panel themes={themes}>
      <Title>
        <PTitle>
          <Img src='./logocompleto.svg' alt='info' />
        </PTitle>
        <Img src='./iconclose.png' alt='iconclose' onClick={() => setVisible(false)} />
      </Title>
      <Container themes={themes}>
        <Informacion>
          <h3>
            Descargo de responsabilidades
          </h3>
          <div>
            <br />
            <p>
              {'El contenido de este sitio de la Georgia Tech Panama Logistics Innovation and Research Center ("GT Panamá") es para fines de información general solamente y se proporciona tal cual. GT Panamá no hace representaciones o garantías de ningún tipo, expresa o implícita, con respecto a, pero no limitado a, la exactitud, integridad, oportunidad, idoneidad, fiabilidad o disponibilidad con respecto a sus sitios web o los contenidos o servicios contenidos en dichos sitios web. El uso de los sitios web GT de Panamá y cualquier dependencia de los contenidos o servicios en ella contenidos son estrictamente bajo su propio riesgo.'}
            </p>
            <br />
            <p>
              {'En ningún caso GT Panamá o Georgia Institute of Technology ("Georgia Tech") será responsable por cualquier pérdida o daño, incluyendo, pero no limitado a, directa, indirectamente, especial, incidental o consecuente, o cualquier pérdida o daño derivada de la pérdida de datos o beneficios que surjan de, o en conexión con, el uso del sitios web de GT de Panamá.'}
            </p>
            <br />
            <p>
              {'El sitio web de GT Panamá puede contener links a otros sitios web y los contenidos y servicios mantenidos y/o prestados por terceros no afiliados con GT Panamá o Georgia Tech y no controlada por GT Panamá o Tech Georgia. El uso de dichos sitios web, contenidos y/o servicios pueden estar sujetos a derechos de propiedad intelectual de terceros y/o regulados por los términos y condiciones de terceros. El uso de tales sitios web de terceros, sus contenidos y/o servicios es bajo su propio riesgo, y debe estar en conformidad con los términos correspondientes a los terceros y sus condiciones, y no se debe infringir los derechos de propiedad intelectual de dichos terceros.'}
            </p>
            <br />
            <p>
              {'Ni GT Panamá ni Georgia Tech respalda el contenido, empresas, productos o servicios de dichos sitios web. Ni GT Panamá ni Georgia Tech se hace responsable por el contenido, productos, servicios u otro material disponible en dichos sitios web o prestados por dichos terceros. Ni GT Panamá ni Georgia Tech serán responsables, directa o indirectamente, por cualquier daño o pérdida causada o supuestamente causada por o en conexión, con el uso o dependencia de cualquier contenido, productos o servicios disponibles en o a través de dichos sitios web.'}
            </p>
          </div>
        </Informacion>
      </Container>
    </Panel>
  )
}

const Img = styled.img`
    min-height: 40px;
    height: 40px;
    margin-right: 10px;
`;

const PTitle = styled.p`
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 0px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Panel = styled.div`
    position: absolute;
    width: 600px;
    height: 400px;
    z-index: 10;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: auto;
    border-radius: 5px;
    padding: 10px;
    max-width: calc(100% - 20px);
    max-height: calc(100% - 80px);
    mix-blend-mode: normal;
    backdrop-filter: blur(20px);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), rgba(67, 67, 67, 0.5);
    color: white;
    border: 1px  solid  ${(props) => props.themes ? '#ffffff80' : '#1d1d1d80'};
    background: ${(props) => props.themes ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), rgba(255, 255, 255, 0.5);' : 'linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), rgba(67, 67, 67, 0.5);'};
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width: 800px) {
      height: 600px;
    }  
`;

const Title = styled.div`
  background: linear-gradient(90deg, rgba(28, 61, 79, 0.6) 44.42%, rgba(87, 112, 124, 0.6) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  width: calc(100%);
  height: auto;
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const Container = styled.div`
  width: calc(100% - 10px);
  height: calc(100% - 60px);
  display: flex;
  flex-direction: row;
  color: ${(props) => props.themes ? 'black' : 'white'};
`;

const Informacion = styled.div`
  margin: 10px 5px;
  padding: 5px;
  height: calc(100% - 30px);
  width: 100%;
  text-align: justify;
  overflow: auto;
  scrollbar-width: thin;
   ::-webkit-scrollbar  
   { 
    width: 5px;
    height: 8px;
    background-color: #aaa;
    border-radius: 2px;
   }

   ::-webkit-scrollbar-thumb {
    background: #1d1d1d;
    border-radius: 2px;
  }
`;

export default Politicas