import React from 'react'
import styled from 'styled-components'
import ListaObjetos from './ListbarObject'

const Listbar = ({ subnav, setSubnav, nivel, FlyToPoint, geodata, quitaricon, viewState, setInfoLayer, setShowInfobar, fondolistbar, themes, showListbar, setShowListbar, titlelistbar, idioma, listbar, setCenter, QuitarClick }) => {
	/* OCULTA LA LISTA */
	const { innerHeight: height } = window;
	const tam = height > 600 ? true : false
	return (
		<>
			<DivListbar themes={themes} showListbar={showListbar} subnav={subnav} tam={tam}>
				<DivInfoTitle fondolistbar={fondolistbar}>
					<DivTitle>
						<DivTitleText>
							{idioma ? titlelistbar[0] : titlelistbar[1]}
						</DivTitleText>
                        <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
						<DivTitleIcon onClick={e => { setSubnav(() => !subnav); }}>
							{
								(subnav
									?<Img src='./closelist.png' alt='close' />
									:  <Img src='./openlist.png' alt='open' />)
							}
						</DivTitleIcon>
						<DivTitleClose>
                        <Img src='./iconclose.png' alt='iconclose' onClick={() => { setShowListbar(false); QuitarClick(); quitaricon(); }}/>
						</DivTitleClose>
                        </div>
					</DivTitle>
				</DivInfoTitle>
				<DivInformation subnav={subnav} tam={tam}>
					{
						subnav && listbar.map(
							(item, index) => {
								return <ListaObjetos nivel={nivel} FlyToPoint={FlyToPoint} geodata={geodata} quitaricon={quitaricon} viewState={viewState} setInfoLayer={setInfoLayer} setShowInfobar={setShowInfobar} key={index} item={item} themes={themes} setCenter={setCenter} setSubnav={setSubnav} />
							}
						)
					}
				</DivInformation>
			</DivListbar>
		</>
	)
}

const DivListbar = styled.div`
 padding: 10px;
 position: fixed;
 display: flex;
 z-index: 1;
 top: 80px;
 right: 50px;
 pointer: default;
 display: ${(props) => props.showListbar ? 'block' : 'none'};
 justify-content: center;
 align-items: center;
 text-align: center;
 box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
 width: 450px;
 border-radius: 5px;
 min-height: 60px;
 height: ${(props) => props.subnav ? 'auto' : '60px'};
 max-height: ${(props) => props.tam ? '600px' : 'calc(100% - 110px)'};
 max-width: 300px;
 background: ${(props) => props.themes ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), rgba(255, 255, 255, 0.5);' : 'linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), rgba(67, 67, 67, 0.5);'};
 border: 1px  solid  ${(props) => props.themes ? '#ffffff80' : '#1d1d1d80'};
 backdrop-filter: blur(20px);
`;

const Img = styled.img`
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
`

const DivInfoTitle = styled.div`
    background: linear-gradient(90deg, ${props => props.fondolistbar}, ${props => props.fondolistbar}, ${props => props.fondolistbar});
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: auto;
    border-radius: 4px;
    padding: 6px;
    color: #FBFBFE;
`;

const DivTitle = styled.div`
    cursor: pointer !important;
    align-items: center;
    height: 30px;
    width: auto;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    letter-spacing: 1px;
    color: white;
`;

const DivTitleText = styled.div`
    cursor: default !important;
    align-items: center;
    height: 30px;
    width: 200px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: white;
`;

const DivTitleIcon = styled.div`
    cursor: pointer !important;
    align-items: right;
    justify-content: center;
    height: 30px;
    width: 30px;
    display: flex;
    flex-direction: row;
    font-size: 20px;
    margin-right: 3px;
`;

const DivTitleClose = styled.div`
    cursor: pointer !important;
    align-items: right;
    justify-content: end;
    height: 30px;
    width: 30px;
    display: flex;
    margin: 0px;
`;

const DivInformation = styled.div`
    margin-top: 10px;
    overflow-y: visible;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    max-height: ${(props) => props.tam ? '520px' : '45vh'};
    ::-webkit-scrollbar  
    {
        width: 0px;
    }
`;

export default Listbar;