import { IconLayer } from '@deck.gl/layers';
import { PolygonLayer } from '@deck.gl/layers';
import { PathLayer } from '@deck.gl/layers';
import { color, colorsatelital, colorsolido, icono } from './IconsColor'
export const Layers = (data, action, setShowInfobar, quitaricon, satelital) => {
    const Point = []
    const MultiPolygon = []
    const Polygon = []
    const MultiLineString = []
    const LineString = []
    const CanalPolygon = []
    data.forEach(
        (x) => (
            x.features.forEach(
                (y) => {
                    if (y.geometry.type === "MultiPolygon") {
                        if (y.geometry.coordinates.length > 1) {
                            insertar({ ...y })
                        }
                        else {
                            MultiPolygon.push(
                                {
                                    id: y.properties.id,
                                    name: y.properties.name,
                                    typepo: y.properties.type,
                                    ...y
                                })
                        }
                    }
                    else if (y.geometry.type === "Polygon" && y.properties.iconcat === 'PC') {
                        CanalPolygon.push(
                            {
                                id: y.properties.id,
                                name: y.properties.name,
                                typepo: y.properties.type,
                                ...y
                            })
                    }
                    else if (y.geometry.type === "Polygon" && y.properties.iconcat !== 'PC') {
                        Polygon.push(
                            {
                                id: y.properties.id,
                                name: y.properties.name,
                                typepo: y.properties.type,
                                ...y
                            })
                    }
                    else if (y.geometry.type === "Point") {
                        Point.push(
                            {
                                id: y.properties.id,
                                name: y.properties.name,
                                type: y.properties.type,
                                ...y
                            })
                    }
                    else if (y.geometry.type === "MultiLineString") {
                        if (y.geometry.coordinates.length > 1) {
                            insertarpath({ ...y })
                        }
                        else {
                            MultiLineString.push({ ...y })
                        }
                    }
                    else if (y.geometry.type === "LineString") {
                        LineString.push({ ...y })
                    }
                    else {
                        // console.log(y)
                    }
                }
            )
        )
    )
    function insertar(y) {
        y.geometry.coordinates.forEach((x, index) => {
            MultiPolygon.push(
                {
                    id: String(y.properties.id).concat('-', index),
                    name: y.properties.name, typepo: y.properties.type,
                    type: y.type,
                    geometry: { type: y.geometry.type, coordinates: x },
                    properties: { ...y.properties }
                })
        })
    }
    function insertarpath(y) {
        y.geometry.coordinates.forEach((x) => {
            LineString.push(
                {
                    type: y.type,
                    geometry: { type: y.geometry.type, coordinates: x },
                    properties: { ...y.properties }
                })
        })
    }
    const PointClean = Point.filter((v, i, a) => a.findIndex(v2 => ['id', 'name', 'type'].every(k => v2[k] === v[k])) === i)
    const MultiPolygonClean = MultiPolygon.filter((v, i, a) => a.findIndex(v2 => ['id', 'name', 'typepo'].every(k => v2[k] === v[k])) === i)
    const PolygonClean = Polygon.filter((v, i, a) => a.findIndex(v2 => ['id', 'name', 'typepo'].every(k => v2[k] === v[k])) === i)

    const pintar = (colors) => {
        if (satelital) {
            return colorsatelital(colors)
        }
        else {
            return colorsolido(colors)
        }
    }
    const LayersPoints = new IconLayer({
        onClick: (info, event) => { action(info.object); setShowInfobar(true); quitaricon() },
        getElevation: 50,
        id: 'icon-layer',
        data: PointClean,
        pickable: true,
        sizeScale: 10,
        sizeMinPixels: 30,
        sizeMaxPixels: 100,
        transitions: { getPosition: { duration: 10 } },
        getSize: d => d.properties.tam ? d.properties.tam : 2,
        getIcon: d => (
            {
                url: icono(d.properties.icon),
                width: 128,
                height: 128
            }
        ),
        getPosition: d => [...d.geometry.coordinates, d.properties.height]
    });
    const LayersMultiPolygons = new PolygonLayer({
        onClick: (info, event) => { action(info.object); setShowInfobar(true); quitaricon() },
        id: 'polygon-layer',
        data: MultiPolygonClean,
        pickable: true,
        stroked: true,
        wireframe: true,
        extruded: true,
        elevationScale: 50,
        getLineWidth: 10,
        getPolygon: d => d.geometry.coordinates[0],
        getFillColor: d => pintar(d.properties.FillColor),
        getElevation: d => d.properties.height,
        getLineColor: d => color(d.properties.LineColor)
    });
    const LayersPolygons = new PolygonLayer({
        onClick: (info, event) => { action(info.object); setShowInfobar(true); quitaricon() },
        id: 'polygon-layer-2',
        data: PolygonClean,
        pickable: true,
        getLineWidth: 10,
        stroked: true,
        wireframe: true,
        extruded: true,
        elevationScale: 50,
        getPolygon: d => d.geometry.coordinates,
        getFillColor: d => pintar(d.properties.FillColor),
        getElevation: d => d.properties.height,
        getLineColor: d => color(d.properties.LineColor)
    });
    const LayersPolygonsCanal = new PolygonLayer({
        onClick: (info, event) => { action(info.object); setShowInfobar(true); quitaricon() },
        id: 'polygon-layer-3',
        data: CanalPolygon,
        lineWidthMinPixels: 1,
        pickable: true,
        getLineWidth: 5,
        stroked: true,
        wireframe: true,
        extruded: false,
        elevationScale: 50,
        getPolygon: d => d.geometry.coordinates,
        getFillColor: d => pintar(d.properties.FillColor),
        getElevation: d => d.properties.height,
        getLineColor: d => color(d.properties.LineColor)
    });
    const LayersMultiLineString = new PathLayer({
        onClick: (info, event) => { action(info.object); setShowInfobar(true); quitaricon() },
        id: 'path-layer',
        data: MultiLineString,
        pickable: true,
        widthScale: 100,
        widthMinPixels: 3,
        getPath: d => [...d.geometry.coordinates[0]],
        getColor: d => pintar(d.properties.FillColor),
        getWidth: d => d.properties.height
    });
    const LayersLineString = new PathLayer({
        onClick: (info, event) => { action(info.object); setShowInfobar(true); quitaricon() },
        id: 'path-layer-2',
        data: LineString,
        pickable: true,
        widthScale: 100,
        widthMinPixels: 3,
        getPath: d => [...d.geometry.coordinates],
        getColor: d => pintar(d.properties.FillColor),
        getWidth: d => d.properties.height
    });
    return {
        LayersPoints, LayersLineString, LayersMultiLineString, LayersPolygons, LayersMultiPolygons, LayersPolygonsCanal
    }
}