import React from 'react'
import { Colores } from '../../Style/Themes'
import { icono } from '../../Process/IconsColor'
import styled from 'styled-components'
import TextoInfo from './InfobarText'
const Infobar = ({ themes, showInfobar, infoLayer, setShowInfobar }) => {
    const x = Object.entries(infoLayer).length
    if (x) {
        const { name, id, height, icon, iconcat, FillColor, LineColor, Fondo, tam, ...otros } = infoLayer.properties
        const ColorFondo = (Fondo ? Fondo : Colores.Dark.nav_background)
        if (Object.entries(otros).length > 0) {
            return (
                <>
                    <DivInfoBar themes={themes} showInfobar={showInfobar} >
                        <DivBackground>
                            <DivTitleInfoBar themes={themes} ColorFondo={ColorFondo} >
                                <DivTitle>
                                    {infoLayer.properties.name}
                                </DivTitle>
                                <DivIcon onClick={() => setShowInfobar(false)} >
                                    <Img src='./iconclose.png' alt='cerrar'></Img>
                                </DivIcon>
                            </DivTitleInfoBar>
                            <DivPropertiesInfoBar themes={themes} >
                                <DivImagen>
                                    <ImgList
                                        src={icono(infoLayer.properties.iconcat)}
                                        alt={infoLayer.properties.name}
                                    ></ImgList>
                                </DivImagen>
                                <DivListProperties>
                                    <TextoInfo x={x} infoLayer={infoLayer} key={x} />
                                </DivListProperties>
                            </DivPropertiesInfoBar>
                        </DivBackground>
                    </DivInfoBar>
                </>
            )
        }
        else {
            return <></>
        }
    }
    else {
        return <></>
    }
}

const ImgList = styled.img`
    width: 100%;
`;


const Img = styled.img`
    min-height: 30px;
    min-width: 30px;
    height: 30px;
    width: 30px;
`

const DivInfoBar = styled.div`
    scrollbar-width: thin;
    position: fixed;
    z-index: 2;
    display: flex;
    bottom: 0;
    right: 0;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
    width: auto;
    border-radius: 5px 5px 0px 0px;
    padding: 5px;
    padding-bottom: 40px;
    max-width: 300px;
    display: ${(props) => props.showInfobar ? 'block' : 'none'};
    background: ${(props) => props.themes ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), rgba(255, 255, 255, 0.5);' : 'linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), rgba(67, 67, 67, 0.5);'};
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
    border: 1px  solid  ${(props) => props.themes ? '#ffffff80' : '#1d1d1d80'};
    backdrop-filter: blur(20px);
`;

//    background-color: ${(props) => props.themes ? Colores.Light.nav_background : Colores.Dark.nav_background};

const DivBackground = styled.div`
    width: 100%;
    height: auto;
    border-radius: 10px;
`;

const DivTitleInfoBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    height: 40px;
    width: auto;
    margin: auto;
    border-radius: 5px;
    color: ${Colores.Dark.text};
    background: linear-gradient(90deg, ${props => props.ColorFondo}, ${props => props.ColorFondo});
`;

// background-color: ${(props) => props.ColorFondo};

// background: linear-gradient(90deg, rgba(64, 140, 255, 0.6) 44.42%, rgba(39, 99, 189, 0.6) 100%);

const DivTitle = styled.div`
    align-items: center;
    height: 30px;
    width: 250px;
    display: flex;
    flex-direction: row;
    font-size: 13px;
    letter-spacing: 1px;
    text-align: left;
`;

const DivPropertiesInfoBar = styled.div`
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 40px);
    padding: 10px;
    border-radius: 5px 5px 5px 5px;
    background-color: white;
`;

const DivIcon = styled.div`
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    display: flex;
    flex-direction: row;
    font-size: 20px;
    letter-spacing: 1.3px;
`;

const DivImagen = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    width: 30%;
    flex-direction: row;
`;

const DivListProperties = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    width: 70%;
    flex-direction: row;
`;
export default Infobar;