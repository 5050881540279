import TNICON from '../Img/ICONOS/TN/TNICON.svg'
import PCRICON from '../Img/ICONOS/TN/TNICON.svg'
import WSICON from '../Img/ICONOS/TN/WSICON.svg'
import TOLLSICON from '../Img/ICONOS/TN/TOLLSICON.svg'
import MPICON from '../Img/ICONOS/TN/MPICON.svg'
import PCICON from '../Img/ICONOS/PC/PCICON.svg'
import CANALICON from '../Img/ICONOS/PC/CANALICON.svg'
import SEICON from '../Img/ICONOS/SE/SEICON.svg'
import AIICON from '../Img/ICONOS/AI/AIICON.svg'
import INTERICON from '../Img/ICONOS/AI/INTERICON.svg'
import LOCALICON from '../Img/ICONOS/AI/LOCALICON.svg'
import SEZICON from '../Img/ICONOS/SEZ/SEZICON.svg'
import LPICON from '../Img/ICONOS/LP/LPICON.svg'
import GTICON from '../Img/ICONOS/GT/GTICON.svg'
import HHICON from '../Img/ICONOS/HH/HHICON.svg'
import FRICON from '../Img/ICONOS/FR/FRICON.svg'
import CSICON from '../Img/ICONOS/TN/CSICON.svg'
const geodata = [
    {
        "id": "A",
        "Color_Fondo": "#E33B00",
        "nameen": "Transportation Network",
        "namees": "Red de Transporte",
        "visible_tree": false,
        "datitos": [],
        "tree": {
            "info": null,
            "listbar": true,
            "subcat": [
                {
                    "id": "A1",
                    "visible_listbar": true,
                    "nameen": "Panama Canal Railway",
                    "namees": "Panama Canal Railway",
                    "link": "https://logistics.gatech.pa/es/assets/railroad",
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#E33B00",
                    "icon": PCRICON,
                    "apikey":"tn_railway"
                },
                {
                    "id": "A2",
                    "visible_listbar": true,
                    "nameen": "Weigh Stations",
                    "namees": "Estaciones de pesos y dimensiones",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#E33B00",
                    "icon": WSICON,
                    "apikey":"tn_weighstation"
                },
                {
                    "id": "A3",
                    "visible_listbar": true,
                    "nameen": "Tolls",
                    "namees": "Garitas de peaje",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#00760C",
                    "icon": TOLLSICON,
                    "apikey":"tn_tolls"
                },
                {
                    "id": "A4",
                    "visible_listbar": true,
                    "nameen": "Panama Metro",
                    "namees": "Metro de Panamá",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#F68E21",
                    "icon": MPICON,
                    "apikey":"tn_metropanama"
                }
                ,
                {
                    "id": "A5",
                    "visible_listbar": true,
                    "nameen": "Charging stations",
                    "namees": "Estaciones de carga",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#0C9D1B",
                    "icon": CSICON ,
                    "apikey":"charging_stations"
                }
            ]
        },
        "file": null,
        "icon": TNICON
    },
    {
        "id": "B",
        "Color_Fondo": "#00199E",
        "nameen": "Panama Canal",
        "namees": "Canal de Panamá",
        "visible_tree": false,
        "tree": {
            "info": "http://www.pancanal.com/",
            "infoes": "http://www.pancanal.com/es",
            "infoen": "http://www.pancanal.com/en",
            "listbar": true,
            "subcat": [
                {
                    "id": "B1",
                    "nameen": "Navigation area",
                    "namees": "Área de navegación",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "icon": CANALICON,
                    "apikey": "panamacanal"
                },
                {
                    "id": "B2",
                    "nameen": "Main components",
                    "namees": "Componentes principales",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#00199E",
                    "visible_listbar": true,
                    "icon": PCICON,
                    "apikey": "canal_maincomponents"
                }
            ]
        },
        "file": null,
        "icon": PCICON
    },
    {
        "id": "C",
        "Color_Fondo": "#6212C7",
        "nameen": "Seaports",
        "namees": "Puertos",
        "visible_tree": false,
        "tree": {
            "info": "https://logistics.gatech.pa/es/assets/seaports",
            "infoes": "https://logistics.gatech.pa/es/assets/seaports",
            "infoen": "https://logistics.gatech.pa/en/assets/seaports",
            "listbar": true,
            "subcat": [
                {
                    "id": "C1",
                    "visible_listbar": true,
                    "nameen": "Containers",
                    "namees": "Contenedores",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#6212C7",
                    "icon": SEICON,
                    "apikey": "seaports_container"
                },
                {
                    "id": "C2",
                    "visible_listbar": true,
                    "nameen": "General cargo",
                    "namees": "Carga general",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#6212C7",
                    "icon": SEICON,
                    "apikey": "seaports_generalcargo"
                },
                {
                    "id": "C3",
                    "visible_listbar": true,
                    "nameen": "Liquid bulk",
                    "namees": "Liquid bulk",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#6212C7",
                    "icon": SEICON,
                    "apikey": "seaports_liquidbulk"
                },
                {
                    "id": "C4",
                    "visible_listbar": true,
                    "nameen": "RoRo",
                    "namees": "RoRo",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#6212C7",
                    "icon": SEICON,
                    "apikey": "seaports_roro"
                },
                {
                    "id": "C5",
                    "visible_listbar": true,
                    "nameen": "Dry bulk",
                    "namees": "Graneles secos",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#6212C7",
                    "icon": SEICON,
                    "apikey": "seaports_drybulk"
                },
                {
                    "id": "C6",
                    "visible_listbar": true,
                    "nameen": "Passengers",
                    "namees": "Pasajeros",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#6212C7",
                    "icon": SEICON,
                    "apikey": "seaports_passengers"
                }
            ]
        },
        "file": null,
        "Fondo": "#123456",
        "icon": SEICON
    },
    {
        "id": "D",
        "Color_Fondo": "#98079B",
        "nameen": "Airports",
        "namees": "Aeropuertos",
        "visible_tree": false,
        "tree": {
            "info": "https://logistics.gatech.pa/en/assets/airports",
            "infoes": "https://logistics.gatech.pa/es/assets/airports",
            "infoen": "https://logistics.gatech.pa/en/assets/airports",
            "listbar": true,
            "subcat": [
                {
                    "id": "D1",
                    "visible_listbar": true,
                    "nameen": "International",
                    "namees": "Internacional",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#98079B",
                    "icon": INTERICON,
                    "apikey": "airport_international"
                },
                {
                    "id": "D2",
                    "visible_listbar": true,
                    "nameen": "Local",
                    "namees": "Local",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "Fondo": "#98079B",
                    "list": false,
                    "icon": LOCALICON,
                    "apikey": "airport_local"
                }
            ]
        },
        "file": null,
        "icon": AIICON
    },
    {
        "id": " E",
        "Color_Fondo": "#CB0000",
        "nameen": "Special Economic Zones",
        "namees": "Zonas Económicas Especiales",
        "visible_tree": false,
        "tree": {
            "info": "https://logistics.gatech.pa/plataforma-logistica/activos-logisticos/regimenes-especiales-de-inversion/regimenes-especiales/",
            "infoes": "https://logistics.gatech.pa/plataforma-logistica/activos-logisticos/regimenes-especiales-de-inversion/regimenes-especiales/",
            "infoen": "https://logistics.gatech.pa/en/logistics-platform/logistics-assets/investment-regimes/investment-regimes/",
            "listbar": true,
            "subcat": [
                {
                    "id": "D1",
                    "visible_listbar": false,
                    "visible_flyto": true,
                    "nameen": "Colon Free Zone",
                    "namees": "Zona Libre de Colón",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#CB0000",
                    "icon": SEZICON,
                    "apikey": "zona_libre_colon"
                },
                {
                    "id": "D2",
                    "visible_listbar": false,
                    "visible_flyto": true,
                    "nameen": "Panama Pacifico",
                    "namees": "Panamá Pacifico",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#CB0000",
                    "icon": SEZICON,
                    "apikey": "zona_libre_panama_pacifico"
                },
                {
                    "id": "D3",
                    "visible_listbar": false,
                    "visible_flyto": true,
                    "nameen": "City of Knowledge",
                    "namees": "Ciudad del Saber",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#CB0000",
                    "icon": SEZICON,
                    "apikey": "ciudad_del_saber"
                },
                {
                    "id": "D4",
                    "visible_listbar": true,
                    "nameen": "Free Trade Zones",
                    "namees": "Zonas Francas",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#CB0000",
                    "icon": SEZICON,
                    "apikey": "zonas_francas"
                }
                ,
                {
                    "id": "D5",
                    "visible_listbar": true,
                    "nameen": "Logistics bonded warehouses",
                    "namees": "Depositos aduaneros Logísticos",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#CB0000",
                    "icon": SEZICON,
                    "apikey": "depositos_aduaneros"
                }
                ,
                {
                    "id": "D6",
                    "visible_listbar": true,
                    "nameen": "Petrolium free zones",
                    "namees": "Zonas libres de combustibles",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#CB0000",
                    "icon": SEZICON,
                    "apikey": "zl_combustible"
                },
                {
                    "id": "D7",
                    "visible_listbar": true,
                    "nameen": "Others",
                    "namees": "Otros",
                    "link": null,
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#CB0000",
                    "icon": SEZICON,
                    "apikey": "ze_others"
                },
                
            ]
        },
        "file": null,
        "icon": SEZICON
    },
    {
        "id": "F",
        "Color_Fondo": "#006A57",
        "nameen": "Logistics Parks",
        "namees": "Parques Logísticos",
        "visible_tree": false,
        "tree": {
            "info": "https://logistics.gatech.pa/es/assets/logistics-parks",
            "infoes": "https://logistics.gatech.pa/es/assets/logistics-parks",
            "infoen": "https://logistics.gatech.pa/en/assets/logistics-parks",
            "listbar": true,
            "subcat": [
                {
                    "id": "F1",
                    "visible_listbar": true,
                    "nameen": "Logistics Parks list",
                    "namees": "Lista de Parques Logísticos",
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#006A57",
                    "icon": LPICON,
                    "apikey": "logisticpark"
                }
            ]
        },
        "file": null,
        "icon": LPICON
    },
    {
        "id": "G",
        "Color_Fondo": "#EA00EF",
        "nameen": "Border crossings",
        "namees": "Pasos de Frontera",
        "visible_tree": false,
        "tree": {
            "info": null,
            "listbar": true,
            "subcat": [
                {
                    "id": "F1",
                    "visible_listbar": true,
                    "nameen": "Border crossings list",
                    "namees": "Lista de Pasos de Frontera",
                    "visible": false,
                    "filejson": {},
                    "list": false,
                    "Fondo": "#EA00EF",
                    "icon": FRICON,
                    "apikey": "border_crossing"
                }
            ]
        },
        "file": null,
        "icon": FRICON
    },
    {
        "id": "H",
        "Color_Fondo": "#408CFF",
        "nameen": "Hub for Humanitarian Logistics",
        "namees": "Hub para Logística Humanitaria",
        "visible_flyto": true,
        "visible_tree": false,
        "tree": {
            "info": null,
            "subcat": null
        },
        "file": {},
        "icon": HHICON,
        "apikey": "humanitary_hub"
    },
    {
        "id": "I",
        "Color_Fondo": "#FFC700",
        "nameen": "Georgia Tech Panama",
        "namees": "Georgia Tech Panamá",
        "visible_flyto": true,
        "visible_tree": false,
        "tree": {
            "info": null,
            "subcat": null
        },
        "file": {},
        "icon": GTICON,
        "apikey": "gatech"
    }
]

export default geodata
