import LPICON from '../Img/ICONOS/LP/LPICON.svg'
import GTICON from '../Img/ICONOS/GT/GTICON.svg'
import ACCESS from '../Img/ICONOS/ACCESS.svg'
import SEZICON from '../Img/ICONOS/SEZ/SEZICON.svg'
import COMBICON from '../Img/ICONOS/SEZ/petro.png'
import ALICON from '../Img/ICONOS/AI/LOCALICON.svg'
import TLICON from '../Img/ICONOS/TN/TLICON.svg'
import INTERICON from '../Img/ICONOS/AI/INTERICON.svg'
import PCICON from '../Img/ICONOS/PC/PCICON.svg'
import TOLLSICON from '../Img/ICONOS/TN/TOLLSICON.svg'
import WSICON from '../Img/ICONOS/TN/WSICON.svg'
import PCRICON from '../Img/ICONOS/TN/TNICON.svg'
import MPICON from '../Img/ICONOS/TN/MPICON.svg'
import SEICON from '../Img/ICONOS/SE/SEICON.svg'
import ARROW from '../Img/arrow.svg'
import HHICON from '../Img/ICONOS/HH/HHICON.svg'
import FRICON from '../Img/ICONOS/FR/FRICON.svg'
import CSICON from '../Img/ICONOS/TN/CSICON.svg'
export const colorsatelital = (hexa) => {
    const colores = hexa.match(/.{1,2}/g);
    const rgb =
        [
            parseInt(colores[0], 16),
            parseInt(colores[1], 16),
            parseInt(colores[2], 16),
            parseInt(colores[3], 16)
        ]
    return rgb
}

export const colorsolido = (hexa) => {
    const colores = hexa.match(/.{1,2}/g);
    const rgb =
        [
            parseInt(colores[0], 16),
            parseInt(colores[1], 16),
            parseInt(colores[2], 16)
        ]
    return rgb
}

export const color = (hexa) => {
    const colores = hexa.match(/.{1,2}/g);
    const rgb =
        [
            parseInt(colores[0], 16),
            parseInt(colores[1], 16),
            parseInt(colores[2], 16),
        ]
    return rgb
}

export const icono = (type) => {
    if (type === 'F'){
        return  LPICON
    }
    else if (type === 'G')
    {
        return GTICON
    }
    else if (type === 'H'){
        return ACCESS
    }
    else if (type === 'E'){
        return SEZICON
    }
    else if (type === 'AL'){
        return ALICON
    }
    else if (type === 'AI'){
        return INTERICON
    }
    else if (type === 'TL'){
        return TLICON
    }
    else if (type === 'PC'){
        return PCICON
    }
    else if (type === 'TO'){
        return TOLLSICON
    }
    else if (type === 'WS'){
        return WSICON
    }
    else if (type === 'PCR'){
        return PCRICON
    }
    else if (type === 'MP'){
        return MPICON
    }
    else if (type === 'SE'){
        return SEICON
    }
    else if (type === 'QQ'){
        return ARROW
    }
    else if (type === 'HH'){
        return HHICON
    }
    else if (type === 'BC'){
        return FRICON
    }
    else if (type === 'CS'){
        return CSICON
    }
    else if (type === 'COMB'){
        return COMBICON
    }
    else{
        return GTICON
    }
}