// LIBRERIAS
import React, { useState, useCallback } from 'react'
import DeckGL, { FlyToInterpolator, LinearInterpolator } from 'deck.gl'
import { _MapContext as MapContext, StaticMap } from 'react-map-gl';
// COMPONENTES
import Control from './MapsControl';
import { Layers } from '../../Process/Layers';
import { AmbientLight, PointLight, LightingEffect } from '@deck.gl/core';

const transitionInterpolator = new LinearInterpolator(['bearing']);

function Maps({ toggleOnInfo, quitaricon, viewState, setViewState, setInitialViewState, initialViewState, themes, datos, action, idioma, setShowInfobar, width }) {
    const ambientLight = new AmbientLight({
        color: [255, 255, 255],
        intensity: 1
    });

    const pointLight = new PointLight({
        color: [255, 255, 255],
        intensity: 2,
        position: [-79.9365, 9.1020, 10000]
    });

    const pointLight2 = new PointLight({
        color: [24, 28, 27],
        intensity: 1,
        position: [-79.433, 8.849, 10000]
    });

    const lightingEffect = new LightingEffect({ ambientLight, pointLight, pointLight2 });

    const theme = {
        effects: [lightingEffect]
    };

    const [satelital, setSatelital] = useState(false);
    const { LayersPoints, LayersLineString, LayersMultiLineString, LayersPolygons, LayersMultiPolygons, LayersPolygonsCanal } = Layers(datos, action, setShowInfobar, quitaricon, satelital)

    function getTooltip({ object }) {
        const name = (idioma ? 'Nombre:' : 'Name: ')
        const type = (idioma ? 'Categoría:' : 'Category: ')
        return (
            object && {
                html: `\
        <div><b> ${name} </b> ${object.properties.name}</div>
        <div><b>${type}</b> ${object.properties.category}</div>
        `
            }
        );
    }

    const goToPanama = useCallback(() => {
        setInitialViewState({
            longitude: -79.68795776367185,
            latitude: 9.119724285282527,
            zoom: 7,
            pitch: 0,
            bearing: 0,
            transitionDuration: 1000,
            transitionInterpolator: new FlyToInterpolator()
        })
    }, []);

    const goToPanamaInicio = useCallback(() => {
        setInitialViewState({
            longitude: -79.68795776367185,
            latitude: 9.119724285282527,
            zoom: 7,
            pitch: 0,
            bearing: 0,
            transitionDuration: 2500,
            transitionInterpolator: new FlyToInterpolator()
        })
    }, []);

    const NuevoPoint = useCallback((x) => {
        const { latitude, longitude, zoom, pitch, bearing } = x
        setViewState({
            longitude: longitude,
            latitude: latitude,
            zoom: zoom,
            pitch: pitch,
            bearing: bearing,
        })
    }, []);

    const rotateCamera = useCallback(() => {
        setInitialViewState(viewState => ({
            ...viewState,
            bearing: viewState.bearing + 120,
            transitionDuration: 1000,
            transitionInterpolator,
            onTransitionEnd: rotateCamera
        }))
    }, []);

    const [iniciar, setIniciar] = useState(true)

    function pasex() {
        if (iniciar) {
            goToPanamaInicio()
            setIniciar(false)
        }
    }

    const girar = useCallback(() => {
        const { longitude, latitude, zoom, bearing, pitch } = viewState
        if (pitch !== 0) {
            setInitialViewState({
                longitude: longitude,
                latitude: latitude,
                zoom: zoom,
                pitch: 0,
                bearing: bearing,
            })
            setViewState({
                longitude: longitude,
                latitude: latitude,
                zoom: zoom,
                pitch: 0,
                bearing: bearing,
            })
        } else {
            setInitialViewState({
                longitude: longitude,
                latitude: latitude,
                zoom: zoom,
                pitch: 60,
                bearing: bearing,
            })
            setViewState({
                longitude: longitude,
                latitude: latitude,
                zoom: zoom,
                pitch: 60,
                bearing: bearing,
            })
        }
    }, [viewState]);;
    return (
        <>
            <DeckGL
                effects={theme.effects}
                initialViewState={initialViewState}
                onViewStateChange={e => { NuevoPoint({ ...e.viewState }) }}
                controller={true}
                layers={[LayersPolygons, LayersMultiPolygons, LayersPoints, LayersLineString, LayersMultiLineString, LayersPolygonsCanal]}
                ContextProvider={MapContext.Provider}
                getTooltip={getTooltip}
                onAfterRender={() => { pasex() }}
            >
                <Control goToPanama={goToPanama} setSatelital={setSatelital} girar={girar} width={width} toggleOnInfo={toggleOnInfo} />
                <StaticMap
                    attributionControl={false}
                    mapboxApiAccessToken={process.env.REACT_APP_KEY_MAPBOX}
                    mapStyle={satelital ? process.env.REACT_APP_SATELITAL_MAPBOX : (themes ? process.env.REACT_APP_LIGHT_MAPBOX : process.env.REACT_APP_DARK_MAPBOX)}
                >
                    <img src='./logocompleto.svg' alt='Logo Georgia Tech' style={Style.logo}></img>
                </StaticMap>
            </DeckGL>

        </>
    )
}

const Style = {
    logo: {
        position: 'absolute',
        display: 'flex',
        bottom: '20px',
        left: '0',
        right: '0',
        width: '300px',
        opacity: 0.3,
        margin: 'auto'
    }
}

export default Maps;