import React from 'react'
import styled from 'styled-components'
import { SIDEBAR } from '../../Data/InfoJson'
import { Colores } from '../../Style/Themes'
const InfoEmpresa = ({ idioma, themes, visible, setVisible}) => {
  if (!visible){
    return (<></>)
  }
  return (
    <Panel themes={themes}>
      <Title>

        <PTitle>
          <Img src='./logocompleto.svg' alt='info' />
        </PTitle>
        <Img src='./iconclose.png' alt='iconclose' onClick={() => setVisible(false)}/>
      </Title>
      <Container themes={themes}>

        <Informacion>
          <table >
            <tbody >
              <tr>
                <Td >{idioma ? SIDEBAR.titleemail.es : SIDEBAR.titleemail.en}</Td>
                <Td ><AText themes={themes} href={`mailto: ${SIDEBAR.email}`}>{SIDEBAR.email}</AText></Td>
              </tr>
              <tr>
                <Td >{idioma ? SIDEBAR.titlewebsite.es : SIDEBAR.titlewebsite.en}</Td>
                <Td><AText themes={themes} href={idioma ? SIDEBAR.website.es : SIDEBAR.website.en} target="_blank" rel="noopener noreferrer">{SIDEBAR.website.link}</AText></Td>
              </tr>
              <tr>
                <Td >{idioma ? SIDEBAR.titlecontact.es : SIDEBAR.titlecontact.en}</Td>
                <Td >{SIDEBAR.contact}</Td>
              </tr>
            </tbody>
          </table>
          <p style={{ textAlign: 'justify', padding: '10px 0px' }}>
            Datos contribuidos por operadores portuarios, MICI, Secretaría Nacional de Energía, Metro de Panamá, ATTT, AAC y AMP.
          </p>
        </Informacion>
      </Container>
    </Panel>
  )
}

const Img = styled.img`
    min-height: 40px;
    height: 40px;
    margin-right: 10px;
`;

const Td = styled.td`
    padding: 10px 5px 0px 0px;
`;

const AText = styled.a`
    text-decoration: none !important;
    color: ${(props) => props.themes ? Colores.Light.text : Colores.Dark.text}
`;


const PTitle = styled.p`
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 0px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Panel = styled.div`
    position: absolute;
    width: 600px;
    height: 300px;
    z-index: 10;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: auto;
    border-radius: 5px;
    padding: 10px;
    max-width: calc(100% - 20px);
    max-height: calc(100% - 80px);
    overflow: auto;
    mix-blend-mode: normal;
    backdrop-filter: blur(20px);
    color: white;
    border: 1px  solid  ${(props) => props.themes ? '#ffffff80' : '#1d1d1d80'};
    background: ${(props) => props.themes ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), rgba(255, 255, 255, 0.5);' : 'linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), rgba(67, 67, 67, 0.5);'};
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width: 800px) {
      height: 400px;
    }  
`;

const Title = styled.div`
  background: linear-gradient(90deg, rgba(28, 61, 79, 0.6) 44.42%, rgba(87, 112, 124, 0.6) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  width: calc(100%);
  height: auto;
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const Container = styled.div`
  width: calc(100% - 10px);
  height: calc(100% - 60px);
  display: flex;
  flex-direction: row;
  color: ${(props) => props.themes ? 'black' : 'white'};
`;

const Informacion = styled.div`
  height: 100%;
  width: 100%;
  word-break: break-all;
`;

export default InfoEmpresa