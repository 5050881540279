export const Colores = {
    Dark: 
    {
        text: '#FBFBFE',
        nav_background: '#252F32E6',
        sibebar_background: '#252F32E6',
        sibebar_list_background: '#626D71E6',
        body_background: '#0f1114',
        menu_button: '#FFFFFF',
        menu_background: '#000000',
        menu_border_color: '#FFFFFF',
    },
    Light:
    {
        text: '#2A3B47',
        nav_background: '#E3E1E1E6',
        sibebar_background: '#E3E1E1E6',
        sibebar_list_background: '#FFFFFFE6',
        body_background: '#90DFFE',
        menu_button: '#1D3354',
        menu_background: '#FFFFFF',
        menu_border_color: '#1D3354',
    }
}