// LIBRERIAS
import React, { useState } from "react";
// COMPONENTES
import Checkbox from "./SidebarLevel2";
import { Colores } from "../../Style/Themes";
import geodata from "../../Data/Main";
import Data from "../../Process/Data";
import CheckInfo from "./SidebarInformation";
import styled from 'styled-components'
import { contadordePoint, BorderColor } from '../../Process/Sidebar'
function SubMenu({ mostrarlistbar, setInfoLayer, setShowInfobar, FlyToPoint, setNivel, quitaricon, setListbar, setShow, setFondolistbar, items, index, themes, idioma, setDatos, setTitleListbar, setShowListbar }) {

    function Cambio(items, tipo = 1, dir = [], punto = {}) {
        try {
            if (tipo === 2) {
                items.visible_tree = true;
            } else {
                items.visible_tree = !items.visible_tree;
            }
            handleOnChange(tipo);
            if (items.visible_tree && items.tree.subcat) {
                items.tree.subcat.map(x => x.visible = true)
            }
            else if (!items.visible_tree && items.tree.subcat) {
                items.tree.subcat.map(x => x.visible = false)
            }
            try {

                if (tipo === 2) {
                    setDatos(Data(geodata))
                    setInfoLayer(punto);
                    setShowInfobar(true);
                    FlyToPoint(dir, 1)
                } else {
                    if (items.visible_tree === false) {
                        quitaricon()
                    }
                    setDatos(Data(geodata))
                }
            } catch (e) { }
        } catch (e) { }
    }

    const handleOnChange = (tipo) => {
        if (tipo === 2) {
            setIsCheck(true);
        }
        else {
            setIsCheck(!isCheck);
        }
    };

    function Info(subnav, Infoes, Infoen, themes) {
        if (subnav) {
            return <CheckInfo Infoes={Infoes} Infoen={Infoen} themes={themes} idioma={idioma} />
        }
    }

    /* ESTO DETECTA SI SE ACTIVARON TODAS LAS SUBCATEGORIAS, SI SE ACTIVARON SE ACTIVA LA CATEGORIA PRINCIPAL */
    const selectAll = () => {
        var contador = 0;
        var cant = items.tree.subcat.length;
        items.tree.subcat.map(
            (x) => {
                if (x.visible) {
                    contador++
                }
                return null
            }
        )
        if (contador === cant) {
            items.visible_tree = true
            setIsCheck(items.visible_tree)
        }
    };

    const [isCheck, setIsCheck] = useState(false);
    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => setSubnav(!subnav);
    function ocultarsibedar() {
        const { innerWidth: width } = window;
        if (width < 800) {
            setShow(false)
        }
    }
    return (
        <>
            <DivCategoria themes={themes} Color_Fondo={items.Color_Fondo} pase={BorderColor(geodata, index)}>
                <DivInfo onClick={(items.tree.subcat || items.tree.info) && showSubnav}>
                    <DivText>
                        <DivIcon Color_Fondo={items.Color_Fondo}>
                            <ImgIcon src={items.icon} alt={items.namees} />
                        </DivIcon>
                        <div style={{ padding: '0px 4px' }}>{idioma ? items.namees : items.nameen}   </div>
                    </DivText>
                    <div>
                        {
                            (items.tree.subcat || items.tree.info) && subnav
                                ? <DivPestana>
                                    {
                                         items.visible_tree 
                                         ? <Img src={themes ? './close.png' : './close.png'} alt={items.namees} />
                                         : <Img src={themes ? './closelight.png' : './close.png'} alt={items.namees} />
                                    }      
                                </DivPestana>
                                : (items.tree.subcat || items.tree.info)
                                    ? <DivPestana><Img src={items.visible_tree ? './open.png' : (themes ? './openlight.png' : './open.png')} alt={items.namees} /></DivPestana>
                                    : items.visible_flyto === true ? <DivVacio themes={themes} onClick={() => { contadordePoint(items.file.features, Cambio, items, quitaricon); ocultarsibedar() }}><Img src={themes ? './flytolight.png' : './flyto.png'} alt={items.namees} /></DivVacio>
                                        : <DivPestana></DivPestana>
                        }
                    </div>
                </DivInfo>
                {
                    items.visible_tree
                        ? <Img src={themes ? './checkactivelight.png' : './checkactive.png'} alt={items.namees} onClick={e => Cambio(items)} />
                        : <Img src={themes ? './checklight.png' : './check.png'} alt={items.namees} onClick={e => Cambio(items)} />
                }
            </DivCategoria>
            {
                (subnav && items.tree.subcat) && items.tree.subcat.map(
                    (subitems, subindex) => {
                        return <Checkbox mostrarlistbar={mostrarlistbar} setInfoLayer={setInfoLayer} setShowInfobar={setShowInfobar} FlyToPoint={FlyToPoint} setNivel={setNivel} quitaricon={quitaricon} setShow={setShow} setFondolistbar={setFondolistbar} setShowListbar={setShowListbar} subitems={subitems} subindex={subindex} key={subindex} themes={themes} idioma={idioma} items={items} index={index} setIsCheck={(x) => setIsCheck(x)} selectAll={selectAll} geodata={geodata} setDatos={setDatos} setTitleListbar={setTitleListbar} setListbar={setListbar} />
                    }
                )
            }
            {
                Info(subnav, items.tree.infoes, items.tree.infoen, themes, idioma)
            }
        </>
    )
}

const DivCategoria = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    letter-spacing: 1.3px;
    padding: 6px;
    height: 40px;
    width: 310px;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 4px;
    background-color: ${(props) => props.themes ? Colores.Light.sibebar_list_background : Colores.Dark.sibebar_list_background};
    background-color: ${props => props.Color_Fondo ? (props.pase ? '#5289B3' : 'none') : 'none'};
    color: ${(props) => props.themes ? Colores.Light.text : Colores.Dark.text};
    color: ${props => props.Color_Fondo ? (props.pase ? '#ffffff' : 'none') : 'none'};
    :hover {
        background-color: #467599 !important;
        border-left: 5px solid #1D3354;
        color: white;
    }
`;

const DivVacio = styled.div`
    color: #1d1d1d;
    border-radius: 5px;
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
    width: 20px;
    font-size: 16px;
`;

const DivIcon = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    min-width: 30px;
    min-height: 30px;
    background: ${props => props.Color_Fondo ? `${props.Color_Fondo}30` : 'rgba(10, 45, 65, 0.2);'};
    border-radius: 4px;
    margin-right: 5px;
`;

const DivPestana = styled.div`
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
    width: 20px;
    font-size: 16px;
`;

const DivInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 260px;
    align-items: center;
    height: 25px;
`

const DivText = styled.div`
    align-items: center;
    justify-content: center;
    height: 25px;
    display: flex;
    flex-direction: row;
    line-height: 16px;
`

const ImgIcon = styled.img`
    min-height: 22px;
    min-width: 22px;
    height: 22px;
    width: 22px;
`
const Img = styled.img`
    min-height: 25px;
    min-width: 25px;
    height: 25px;
    width: 25px;
`

export default SubMenu;


