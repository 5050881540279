export const contadordePoint = (points, Cambio, item, quitaricon) => {
    let contador = 0;
    let direccion = [1, 2];
    let infor = {}
    points.forEach(item => {
        if (item.geometry.type === 'Point') {
            contador++;
        }
    })
    if (contador === 1) {
        quitaricon()
        points.forEach(item => {
            if (item.geometry.type === 'Point') {
                item.properties.icon = 'QQ';
                item.properties.tam = 4;
                infor = { ...item };
                direccion = item.geometry.coordinates;
            }
        })
        Cambio(item, 2, direccion, infor)
    }
}

export const contadordePoint2 = (points, Cambio, item, quitaricon) => {
    let contador = 0;
    let direccion = [1, 2];
    let infor = {}
    points.forEach(item => {
        if (item.geometry.type === 'Point' && item.properties.iconcat !== 'H') {
            contador++;
        }
    })
    if (contador === 1) {
        quitaricon()
        points.forEach(item => {
            if (item.geometry.type === 'Point' && item.properties.iconcat !== 'H') {
                item.properties.icon = 'QQ';
                item.properties.tam = 4;
                infor = { ...item };
                direccion = item.geometry.coordinates;
            }
        })
        Cambio(item, 2, direccion, infor)
    }
}

export const BorderColor = (geodata, index) => {
    let pase = false;
    if (geodata[index].tree.subcat != null) {
        geodata[index].tree.subcat.forEach(
            (x) => {
                if (x.visible === true) {
                    pase = true;
                }
            }
        )
    }
    else {
        pase = geodata[index].visible_tree
    }
    return pase;
}