// COMPONENTES
import { Colores } from '../../Style/Themes'
import React from 'react'
import styled from 'styled-components'
import { SIDEBAR } from '../../Data/InfoJson'
const SidebarRegresar = ({ themes, idioma }) => {
    const link = (idioma ? SIDEBAR.linkregresar.es : SIDEBAR.linkregresar.en)
    return (
        <DivRegresar onClick={() => window.open(link, "_self")} themes={themes}>
            <DivRegresarIcon>
                <LogoImg src='/logo512.png' alt='logo georgia tech' />
            </DivRegresarIcon>
            <DivRegresarText>
                {idioma ? SIDEBAR.titleregresar.es : SIDEBAR.titleregresar.en}
            </DivRegresarText>
        </DivRegresar>
    )
}

const DivRegresar = styled.div`
    color: ${(props) => props.themes ? Colores.Light.text : Colores.Dark.text};
    background-color: ${(props) => props.themes ? Colores.Light.sibebar_list_background : Colores.Dark.sibebar_list_background};
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-size: 14px;
    letter-spacing: 1.3px;
    padding: 5px;
    height: 40px;
    width: 310px;
    margin-left: 10px;
    border-radius: 4px;
    cursor: pointer;
    :hover {
        background-color: #467599 !important;
        border-left: 5px solid #1D3354;
        color: white;
    }
`;

const LogoImg = styled.img`
  height: 22px;
  width: 22px;
`;

const DivRegresarIcon = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: rgba(10, 45, 65, 0.2);
    border-radius: 4px;
`;

const DivRegresarText = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 25px;
    margin-left: 7px;
`;

export default SidebarRegresar;